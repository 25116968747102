.login-container {
  display: flex;
  align-items: center;
  height: 100vh;
  justify-content: center;
  flex-direction: column;

  & .card {
    width: 22.5rem;
  }

  & .spacer {
    height: 10rem;
  }

  & .btn {
    width: 100%;
  }
}                    