.link-danger {
  color: red !important;
}

.markdown > table thead th,
.table thead th {
  text-transform: none !important;
}

.mw-33 {
  min-width: 33%;
}

.mh-80vh {
  max-height: 80vh;
}

.minh-80vh {
  min-height: 80vh;
}

.blocked {
  &::after {
    position: absolute;
    content: 'LOCKED';
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: xx-large;
    font-weight: bolder;
    color: white;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1090;
    background: rgba(0, 0, 0, 0.2);
  }
}

.last-child-my-0:last-child {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
 
body ::-webkit-scrollbar {
  height: 10px;
}

.bg-gray-lt {
  // light grey
  background-color: #f4f4f4 !important;
}