@keyframes move-left-right {
  0% {
    left: 0;
  }  
  50% {
    left: 75vw
  }
  100% {
    left: 0;
  }
}

.progress-bar {
  height: 4px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
  background-color: var(--tblr-primary);
  width: 25%;
  animation: move-left-right 1.5s ease infinite;
}