.react-date-picker {
  display: inline-flex;
  position: relative;
  width: 100%;
}
.react-date-picker,
.react-date-picker *,
.react-date-picker *:before,
.react-date-picker *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.react-date-picker--disabled {
  background-color: #f2f2f2;
  color: #6d6d6d;
}

.react-date-picker__wrapper {
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  width: 100%;
  padding: 0.245rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  color: #232e3c;
  background-clip: padding-box;
  border: 1px solid #dadcde;
  -webkit-appearance: none;
  appearance: none;
  border-radius: 4px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &:has(input:focus) {
    color: inherit;
    background-color: #ffffff;
    border-color: #90b5e2;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(32, 107, 196, 0.25);
  }
}

.react-date-picker__inputGroup {
  min-width: calc((4px * 3) + 0.54em * 8 + 0.217em * 2);
  flex-grow: 1;
  box-sizing: content-box;
}
.react-date-picker__inputGroup__divider {
  padding: 1px 0;
  white-space: pre;
}
.react-date-picker__inputGroup__input {
  min-width: 0.54em;
  height: 100%;
  position: relative;
  padding: 0 1px;
  border: 0;
  background: none;
  font: inherit;
  box-sizing: content-box;
  -moz-appearance: textfield;
}
.react-date-picker__inputGroup__input::-webkit-outer-spin-button,
.react-date-picker__inputGroup__input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.react-date-picker__inputGroup__input:invalid {
  background: rgba(255, 0, 0, 0.1);
}
.react-date-picker__inputGroup__input--hasLeadingZero {
  margin-left: -0.54em;
  padding-left: calc(1px + 0.54em);
}
.react-date-picker__button {
  border: 0;
  background: transparent;
  padding: 4px 6px;
}
.react-date-picker__button:enabled {
  cursor: pointer;
}
.react-date-picker__button:enabled:hover .react-date-picker__button__icon,
.react-date-picker__button:enabled:focus .react-date-picker__button__icon {
  stroke: #0078d7;
}
.react-date-picker__button:disabled .react-date-picker__button__icon {
  stroke: #6d6d6d;
}
.react-date-picker__button svg {
  display: inherit;
}
.react-date-picker__calendar {
  width: 350px;
  max-width: 100vw;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1;
}
.react-date-picker__calendar--closed {
  display: none;
}
.react-date-picker__calendar .react-calendar {
  border-width: thin;
}


.react-calendar {
  background-color: #fff;
  padding: 1em;
  border: 1px solid #dadcde;
  border-radius: 4px;

  button {
    border: 0;
    box-shadow: none;
    padding: .5em .1em;
    background-color: transparent;
  }

  &__month-view__days__day--neighboringMonth {
    color: #dadcde;
  }

  &__tile {
    border-radius: 4px;    
    border: 1px solid transparent;
  }

  &__tile--active {
    background-color: #0078d7 !important;
    color: white;    
  }
  &__tile:hover {
    color: #0078d7 !important;
    border: 1px solid #0078d7;
  }
}
